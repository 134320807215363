import { Component, OnInit, ElementRef } from "@angular/core";
import { ROUTES } from "../../sidebar/sidebar.component";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { Route, Router } from "@angular/router";
import { NavigationService } from "app/services/navigation/navigation.service";

@Component({
    // moduleId: module.id,
    selector: "navbar-cmp",
    templateUrl: "navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;
    navTitle: String;
    navBackLink: String;

    constructor(
        location: Location,
        private element: ElementRef,
        private router: Router,
        private navigationService: NavigationService
    ) {
        this.location = location;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter((listTitle) => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];

        this.navigationService.titleChange.subscribe((value) => {
            this.navTitle = value;
        });
        this.navigationService.backLinkChange.subscribe((value) => {
            this.navBackLink = value;
        });
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName("body")[0];
        setTimeout(function () {
            toggleButton.classList.add("toggled");
        }, 500);
        body.classList.add("nav-open");

        this.sidebarVisible = true;
    }
    sidebarClose() {
        const body = document.getElementsByTagName("body")[0];
        this.toggleButton.classList.remove("toggled");
        this.sidebarVisible = false;
        body.classList.remove("nav-open");
    }
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    logout() {
        localStorage.clear();
        return this.router.navigate(["/"]);
    }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    public backLink: String = "";
    public title: String = "";

    backLinkChange: Subject<String> = new Subject<String>();
    titleChange: Subject<String> = new Subject<String>();

    constructor() {}

    setNavigation(backLink: String, title: String) {
        this.titleChange.next(title);
        this.backLinkChange.next(backLink);
    }
}
